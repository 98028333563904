import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Seo from '../components/seo';
import HomeIcon from '../icons/buttons/pixfort-icon-home-7.svg';
import PixfortBuilderIcon from '../icons/buttons/pixfort-icon-pixfort-builder-1.svg';

const NotFoundPage = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata.title;

	return (
		<Layout location={location} title={siteTitle}>
			<Seo title='Page Not Found' />
			<div className=''>
				<main className='mx-auto max-w-7xl mt-24 px-4 sm:mt-24 sm:px-6 md:mt-36 lg:px-8'>
					<div className='text-center relative z-10'>
						<h1 className='max-w-lg inline-block text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl md:text-6xl'>
							<span className='block xl:inline leading-[1.3]'>
								Ops!
								<span className='relative inline-flex ml-3 text-transparent bg-clip-text bg-gradient-to-r from-[#52FFCF] dark:from-[#fff]/60 via-[#5592FF] dark:via-white to-[#FF71C4] dark:to-[#fff]/40 '>Page not found...</span>
							</span>
						</h1>
						<div>
							<p className='inline-block text-base font-light text-gray-400 sm:mx-auto sm:text-lg md:text-xl lg:mx-0 mt-6'>The page you're looking for is not available.</p>
						</div>
						<div className='mt-5 sm:mt-8 sm:flex sm:justify-center'>
							<div className=''>
								<a
									href='/'
									className='flex w-full items-center justify-center rounded-md border border-black/10 dark:border-white/20 bg-primary px-8 py-3 text-base font-semibold text-white hover:bg-primary/90 md:py-3 md:px-5 transition ease-in-out hover:-translate-y-1 duration-200 shadow-lg shadow-primary/25  focus:outline-none'
								>
									<HomeIcon className='inline-block mr-2' />
									Go back to home
								</a>
							</div>
						</div>
						<div className='flex justify-center mt-12'>
							<div className='max-w-xl mx-auto bg-white shadow-2xl shadow-slate-300/50 dark:shadow-black/20 border border-gray-200 dark:border-white/10 dark:bg-gray-800 px-10 pb-10 pt-8 rounded-xl'>
									<h4 className='my-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white'>Looking for pixfort builder and MEGAPACK?</h4>
									<p className='mb-8 mt-4 text-gray-400 dark:text-white/50'>
										pixfort builder is now available on{' '}
										<a href='https://builder.pixfort.com/' className='font-bold text-primary' target='_blank'>
											builder.pixfort.com
										</a>
									</p>
									<a
										href='https://builder.pixfort.com/login'
										target='_blank'
										className='text-gray-700 hover:text-gray-900 dark:text-white dark:hover:text-gray-300 py-3 px-5 sm:mr-2 mb-2 bg-white dark:bg-white/20 text-sm font-semibold rounded-md focus:outline-none transition ease-in-out hover:-translate-y-0.5 duration-200 border border-black/5 dark:border-white/10'
									>
										<PixfortBuilderIcon className='inline-block mr-1' />
										Go to pixfort builder
									</a>
							</div>
						</div>
					</div>
				</main>
			</div>
		</Layout>
	);
};

export default NotFoundPage;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
